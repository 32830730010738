.jl_champ_donnee_form_connexion
{
    border-radius: 0px;
    border-color: black;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: black;
    font-family: geoSansLight;
    margin-top: 20px;
    margin-bottom: 20px;
}
.jl_champ_donnee_form_connexion:focus
{
    -webkit-box-shadow: none;
    box-shadow: none;   
    border-color: lightgrey;
}
.jl_champ_donnee_form_connexion > input:not(:checked)
{
    border-color: black;
    border-radius: 0px;
    border-radius: 0px;
}
.jl_champ_donnee_form_connexion > input:focus
{
    border-color: grey;
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: white;
}
.jl_champ_donnee_form_connexion > input:checked
{
    background-color: black;
    border-color: black;
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: white;
}
.jl_bouton_form_form_connexion
{
    margin-top: 10px;
    font-family: theBoldFont;
    letter-spacing: 5px;
    background-color: transparent;
    color: black;
    border-radius: 0px;
    border-color: black;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.jl_bouton_form_form_connexion:hover
{
    background-color: black;
    border-color: black;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.jl_bouton_form_form_connexion:focus
{
    background-color: black;
    border-color: black;
    -webkit-box-shadow: none;
    box-shadow: none;
}