.resultat_recherche
{
    margin-top: 25px;
    margin-bottom: 25px;
}
.bouton_resultat_recherche
{
    font-family: theBoldFont;
    letter-spacing: 5px;
    background-color: black;
    border-radius: 0%;
    color:white;
    border-color: black;
    padding-left: 20px;
    padding-right:20px;
    padding-top:10px;
    padding-bottom: 10px;
}
.bouton_resultat_recherche:hover
{
    background-color: purple;
    border-color: purple;
}