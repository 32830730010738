.image_bibliotheque
{
    margin: 25px;
    width: 150px;
    height: 150px;
    cursor: pointer;
    object-fit: cover;
}
.aucune_image_bibliotheque
{
    margin-top: 50px;
}