.jl_titre_album
{
    font-family: theBoldFont;
    font-size: 20px;
    background-color: black;
    padding: 20px;
    margin: 10px;
    color: white;
    max-width: fit-content;
    text-decoration: none;
}
.jl_titre_album:hover
{
    color:lightgray;
}