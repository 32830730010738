.jl_conteneur_accueil_avis
{
    max-width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px;
    margin-bottom:50px
}
.jl_accueil_avis_alignement
{
    align-items: center;
}
.jl_accueil_avis_titre
{
    font-family: theBoldFont;
    color: white;
    font-size: 32px;
    padding: 30px;
}
.jl_accueil_avis_bouton
{
    font-family: theBoldFont;
    background-color: rgba(0, 0, 0, 0.75);
    border: 0px;
    border-radius: 0px;
    color: white;
    font-size: 32px;
}
.jl_accueil_avis_bouton:hover
{
    background-color: transparent;
    font-size: 36px;
    background-color: rgba(0, 0, 0, 0.75);
}
.jl_accueil_avis_texte
{
    color: white;
    margin: 0px;
    padding: 25px;
    background-color: rgba(0, 0, 0, 0.75);
    font-family: geoSansLight;
}