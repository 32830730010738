.conteneur_video_biographie
{
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; 
    overflow: hidden; 
    max-width: 100%; 
    height: auto; 
    margin: auto;
    margin-top: 50px;
} 
.conteneur_video_biographie iframe, .embed-container object, .embed-container embed 
{ 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
}