@font-face {
    font-family: theBoldFont;
    src: url("../../../public/polices/the_bold_font/THEBOLDFONT.ttf");
}
.conteneur_navigation
{
    display: flex;
    align-items: center;
}
.jl_item_navigation
{
    font-family: theBoldFont;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: lighter;
    color: black;
    margin: 10px;
}
.jl_item_navigation:hover
{
    color: purple;
}
.jl_icone
{
    vertical-align:baseline;
}
.jl_texte_icone
{
    display: inline-block;
    margin-left: 5px;
}
.jl_champ_donnee_recherche
{
    border-radius: 0px;
    border-color: black;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: black;
    font-family: geoSansLight;
}
.jl_champ_donnee_recherche:focus
{
    -webkit-box-shadow: none;
    box-shadow: none;   
    border-color: lightgrey;
}
.jl_champ_donnee_recherche > input:not(:checked)
{
    border-color: black;
    border-radius: 0px;
    border-radius: 0px;
}
.jl_champ_donnee_recherche > input:focus
{
    border-color: grey;
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: white;
}
.jl_champ_donnee_recherche > input:checked
{
    background-color: black;
    border-color: black;
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: white;
}
.jl_bouton_recherche
{
    font-family: theBoldFont;
    font-size: 8px;
    letter-spacing: 5px;
    background-color: transparent;
    color: black;
    border-radius: 0px;
    border-color: black;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.jl_bouton_recherche:hover
{
    background-color: black;
    border-color: black;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.jl_bouton_recherche:focus
{
    background-color: black;
    border-color: black;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.jl_bouton_recherche:disabled
{
    background-color: black;
    border-color: black;
    -webkit-box-shadow: none;
    box-shadow: none;
}