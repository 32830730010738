.jl_image
{
   margin-top: 25px;
   margin-bottom: 25px;
   transition: width 1s;
   transition-timing-function: linear;
   max-height: 400px;
}
.jl_image:hover
{
    width: 90%;
}
.jl_image_sans_lien
{
    margin-top: 25px;
    margin-bottom: 25px;
    max-height: 400px;
}