.conteneur_selection_fichier
{
    cursor: pointer;
}
.zone_depot_image
{
    background-color: black;
    padding: 25px;
}
.zone_depot_image_couleur
{
    background-color: gray;
    padding: 25px;
}
.texte_depot_image
{
    margin: 0px;
    color:white;
}
.file_element
{
    display: none;
}