.conteneur_carrousel
{
    max-height: 550px;
}
.image_carrousel
{
    max-height: 550px;
}
.jl_zone_texte_carrousel
{
   bottom: 30%;
}
.jl_premier_titre_zone_texte_carrousel
{
    font-family: theBoldFont;
    font-size: 18px;
    letter-spacing: 10px;
}
.jl_titre_zone_texte_carrousel
{
    font-family: theBoldFont;
    font-size: 72px;
}
.jl_bouton_zone_texte_carrousel
{
    font-family: theBoldFont;
    letter-spacing: 5px;
    background-color: transparent;
    border-radius: 0%;
    color:white;
    border-color: white;
    margin-top: 20px;
    padding-left: 20px;
    padding-right:20px;
    padding-top:10px;
    padding-bottom: 10px;
}
.jl_bouton_zone_texte_carrousel:hover
{
    background-color: white;
    border-color: white;
    color: black;
}
@media screen and (max-width: 1275px) {
    .jl_premier_titre_zone_texte_carrousel
    {
        font-family: theBoldFont;
        font-size: 14px;
        letter-spacing: 10px;
    }
    .jl_titre_zone_texte_carrousel
    {
        font-family: theBoldFont;
        font-size: 62px;
    }
}
@media screen and (max-width: 1085px) {
    .jl_zone_texte_carrousel
    {
        bottom: 35%;
    }
    .jl_premier_titre_zone_texte_carrousel
    {
        font-family: theBoldFont;
        font-size: 12px;
        letter-spacing: 10px;
    }
    .jl_titre_zone_texte_carrousel
    {
        font-family: theBoldFont;
        font-size: 52px;
    }
}
@media screen and (max-width: 920px) {
    .jl_premier_titre_zone_texte_carrousel
    {
        font-family: theBoldFont;
        font-size: 12px;
        letter-spacing: 10px;
    }
    .jl_titre_zone_texte_carrousel
    {
        font-family: theBoldFont;
        font-size: 48px;
    }
}
@media screen and (max-width: 850px) {
    .jl_premier_titre_zone_texte_carrousel
    {
        font-family: theBoldFont;
        font-size: 12px;
        letter-spacing: 10px;
    }
    .jl_titre_zone_texte_carrousel
    {
        font-family: theBoldFont;
        font-size: 40px;
    }
}
@media screen and (max-width: 725px) {
    .jl_zone_texte_carrousel
    {
        bottom: 30%;
    }
    .jl_premier_titre_zone_texte_carrousel
    {
        font-family: theBoldFont;
        font-size: 12px;
        letter-spacing: 10px;
    }
    .jl_titre_zone_texte_carrousel
    {
        font-family: theBoldFont;
        font-size: 40px;
    }
}
@media screen and (max-width: 500px) {
    .jl_zone_texte_carrousel
    {
        bottom: 25%;
    }
    .jl_premier_titre_zone_texte_carrousel
    {
        font-family: theBoldFont;
        font-size: 12px;
        letter-spacing: 10px;
    }
    .jl_titre_zone_texte_carrousel
    {
        font-family: theBoldFont;
        font-size: 28px;
    }
}