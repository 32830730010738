.jl_conteneur_texte_biographie
{
    margin-top: 50px;
}
.jl_texte_biographie
{
    font-family: geoSansLight;
    font-size: 18px;
}
.image_jessie_biographie
{
    max-height: 400px;
}