.jl_conteneur_carte_album
{
    background-color: black;
    border: 0px;
    color: white;
    margin-bottom: 50px;
}
.jl_titre_accueil_liste_album
{
    font-family: theBoldFont;
    font-size: 32px;
    letter-spacing: 2px;
    margin-bottom: 50px;
}
.jl_titre_carte_album
{
    font-size: 28px;
    font-family: theBoldFont;
}
.jl_bouton_carte_album
{
    font-family: theBoldFont;
    font-size: 14px;
    background-color: transparent;
    border-radius: 0px;
    border-color: white;
    padding-left: 20px;
    padding-right:20px;
    padding-top:10px;
    padding-bottom: 10px;
}
.jl_texte_carte_album
{
    font-family: geoSansLight;
}