.jl_titre_tarif
{
    font-family: theBoldFont;
    padding: 20px;
    font-size: 18px;
}
.jl_image_tarif_primaire
{
    height: 500px;
}
.jl_image_tarif_secondaire
{
    height: 400px;
}
.jl_texte_tarif
{
    margin-top: 10px;
    padding: 50px;
    font-family: geoSansLight;
    text-align: justify;
}
.jl_bouton_tarif
{
    font-family: theBoldFont;
    letter-spacing: 5px;
    background-color: transparent;
    border-radius: 0%;
    color:black;
    border-color: black;
    padding-left: 20px;
    padding-right:20px;
    padding-top:10px;
    padding-bottom: 10px;
}
.jl_bouton_tarif:hover
{
    background-color: black;
    color: white;
    border-color: black;
}