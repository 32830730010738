@font-face {
    font-family: theBoldFont;
    src: url("../../public/polices/the_bold_font/THEBOLDFONT.ttf");
}
.bouton
{
    font-family: theBoldFont;
    letter-spacing: 5px;
    background-color: transparent;
    border-radius: 0%;
    color:black;
    border-color: black;
    margin-top: 20px;
    padding-left: 20px;
    padding-right:20px;
    padding-top:10px;
    padding-bottom: 10px;
}
.bouton:hover
{
    background: black;
    color: white;
    border-color: black;
}
.jl_image_pas_non_trouve
{
    margin-top:50px;
}
.titre_description_image
{
    font-family: theBoldFont;
    margin-top: 25px;
}
.description_image
{
    background-color: black;
    padding: 10px;
    color: white;
    margin-top: 25px;
}
.element_sitemap
{
    display: block;
    background-color: black;
    color: white;
    margin: 20px;
    padding: 20px;
}
.lien_sitemap
{
    text-decoration: none;
}