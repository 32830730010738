.jl_conteneur_pied_page
{
    font-family: geoSansLight;
    align-items: center;
    margin-top: 25px;
}
.jl_titre_pied_page
{
    font-family: theBoldFont;
}
.jl_lien_createur_pied_page
{
    color:purple;
    text-decoration: none;
}
.jl_lien_createur_pied_page:hover
{
    color:black;
}
.lien_pied_page
{
    color:purple;
    text-decoration: none;
}
.lien_pied_page:hover
{
    color:black;
    text-decoration: none;
}
.copyright
{
    margin-top: 25px;
}