.jl_alerte
{
    margin-top: 25px;
    margin-bottom: 25px;
    background-color: black;
    color: white;
    font-family: geoSansLight;
    display: flex;
    align-items: center;
    justify-content: center;
}
.jl_alerte > svg
{
    margin-right: 10px;
}